export const targets = [
  "inputEmail",
  "inputAmount",
  "inputFirstName",
  "inputLastName",
  "inputCity",
  "inputState",
  "inputZipCode",
  "inputAddress",
  "inputMobilePhone",
  "inputHomePhone",
  "inputCountry",
  "inputSponsoredProject",
  "input",
  "amount",
  "firstNameContainer",
  "lastNameContainer",
  "emailContainer",
  "donationsForm",
  "inputBillingName",
  "inputBillingEmail",
  "inputBillingPhoneNumber",
  "inputBillingLastName",
  "inputBillingCity",
  "inputBillingState",
  "inputBillingZipCode",
  "inputBillingAddress",
  "inputBillingPhone",
  "inputBillingCountry",
  "inputMemberInformationMemorialAndHonor",
  "billingFirstNameContainer",
  "billingLastNameContainer",
  "billingAddressContainer",
  "billingCityContainer",
  "billingStateContainer",
  "sponsoredProjectContainer",
  "billingCountryContainer",
  "billingPhoneContainer",
  "billingZipCodeContainer",
  "billingEmailContainer",
  "amountContainer",
  "inputBirthDate",
  "amount",
  "birthDateContainer",
  "checkboxInputOrganization",
  "inputOrganizationName",
  "inputOrganizationEmail",
  "organizationNameContainer",
  "organizationEmailContainer",
  "userEmailContainer",
  "gemNameContainer",
  "inputGemName",
  "memberInformationContainer",
  "inputMemberInformationMemorialAndHonor",
  "billingCardNumberContainer",
  "inputCreditCardNumber",
  "billingCVVCodeContainer",
  "inputCreditCardCVVCode",
  "billingExpirationDateContainer",
  "inputCreditCardExpiration",
  "billingCardHolderNameContainer",
  "inputCreditCardHolderName",
  "contributeAmount",
  "checkboxInputMemorialAndHonor",
  "contributeAmountContainer",
  "btnConfirmForm",
  "messageAwaitDonation",
  "openModalConfirmDonation",
  "captchaContainer",
  "inputCaptcha",
  "tShirtContainer",
  "inputTShirtQuantity",
  "inputTShirtSizes",
  "tShirtSizesContainer",
  "tShirtSizesItemsContainer"
]
